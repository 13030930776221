import React from 'react';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';

export default function GMTextField(props){
    //i18n
    const { t, i18n } = useTranslation(props.translation);

    if(props.translation){
        return(
            <div className="form-div d-flex flex-column">
                    <label className='ms-0 gmfield'>{t(`label_${props.field_name}`)}</label>
                    <TextField
                        fullWidth
                        type={props.type}
                        className="gmfield"
                        placeholder = {t(`placeholder_${props.field_name}`)} 
                        name={props.field_name}
                        
                        // value={formData[props.field_name]}
                        // onChange={handleChange}
                        // helperText={formError[props.field_name]}
                        // error={formError[props.field_name]}
                        variant="outlined"
                        
                        size={'small'}
                        
                        {...props}
                    />
            </div>
        )
    }else{
        return(
            <div className="form-div d-flex flex-column">
                    <label className='ms-0 gmfield'>{props.field_name}</label>
                    <TextField
                        fullWidth
                        className="gmfield"
                        placeholder = {props.field_name} 
                        name={props.field_name}
                        
                        // value={formData[props.field_name]}
                        // onChange={handleChange}
                        // helperText={formError[props.field_name]}
                        // error={formError[props.field_name]}
                        variant="outlined"
                        
                        size={'small'}
                        
                        {...props}
                    />
            </div>
        )
    }

    
}