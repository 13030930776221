import React, {useEffect } from 'react';
import { useState } from "react"
import { Link } from "react-router-dom";
import * as gmAPI from "../../utils/GMillionAPI";

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Icon from '@mui/material/Icon';
import { useNavigate } from "react-router-dom";
import {RotatingLines} from 'react-loader-spinner';

import * as Validators from '../../utils/validators'

import { useTranslation } from 'react-i18next';

export default function Signup(){
    //i18n
    const { t, i18n } = useTranslation('register');

    const navigate = useNavigate();

    //TODO do this in env
    // const apiKey = 'EZXlwsZcqi16w3AMIRtBG3C6WmEKGeC17DMZrPzs';
    // // const apiKey = 'd41d8cd98f00b204e9800998ecf8427e';   //loc

    const [formData, setFormData] = useState({
        email: (process.env.REACT_APP_ENV === 'dev' ?'tanit+00@gmillion.io' : ''),
        password: (process.env.REACT_APP_ENV === 'dev' ?'12345678@aA':''),
        confirmPassword: (process.env.REACT_APP_ENV === 'dev' ?'12345678@aA':''),
        confirmTos: false,
    });
    const [emailError, setEmailError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);
    const [confirmPasswordError, setConfirmPasswordError] = useState(null);
    const [confirmTosError, setConfirmTosError] = useState(null);
    const [submitDisable, setSubmitDisable] = useState(false);
    const [returnError, setReturnError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value, type, files, checked } = e.target;
        const newValue = type === 'file' ? files[0] : (type === 'checkbox' ? checked : value);

        switch(name){
            case 'email':
                if(newValue.length > 128) return;

            case 'password':
            case 'confirmPassword':
                if(newValue.length > 32) return;
        }

        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: newValue,
        }));
    };

    const validation = (e) => {
        setEmailError(null);
        setPasswordError(null);
        setConfirmPasswordError(null);
        setConfirmTosError(null);

        //validate email
        if(!Validators.validateEmail(formData.email)){
            setEmailError(t('signup.error_email'));
            return false;
        }

        //validate password
        if(!Validators.validatePassword(formData.password)){
            setPasswordError(t('signup.error_password'))
            return false;
        }

        //validate confirm password
        if(!(formData.password == formData.confirmPassword)){
            setConfirmPasswordError(t('signup.error_confirmPassword'));
            return false;
        }
        
        //validate tos
        if(!formData.confirmTos){
            setConfirmTosError('form-error');
            return false;
        }

        return true;
    }

    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(!validation()){
            return;
        }

        setReturnError(null);
        setLoading(true);
        setSubmitDisable(true);
        
        // alert('test');

        try{
            setFormData((prevFormData) => ({
                ...prevFormData,
                displayname: prevFormData.email,
            }));

            // // alert('axios...');
            // axios.defaults.withCredentials = true;
            // // axios.defaults.baseURL = 'http://localhost:3000/loc'
            // axios.defaults.baseURL = 'https://qapi.gmillion.io';
            // // axios.defaults.baseURL = 'https://lkikzolawa.execute-api.ap-southeast-1.amazonaws.com/qa';
            const response = await gmAPI.post(
                '/users', 
                JSON.stringify({ 
                    email: formData.email,
                    displayname: '',
                    password: formData.password,
                })
            )

            //on success register goto email-verifi page
            navigate(`/register?email=${encodeURIComponent(formData.email)}`);

        }catch (err) {
            console.log(err);

            if (!err?.response) {
                setReturnError(t('signup.err504'));
                // alert("No Server Response");
            }else if (err.response?.status == null){
                setReturnError(t('signup.err500'));
            }else{
                setReturnError( t(`signup.err${err.response?.status}`) );
            }
            // errRef.current.focus();
        }finally{
            setLoading(false);
            setSubmitDisable(false);
        }
    }

    return(
        <div className='signup'>
            {(loading && <div className='loader d-flex justify-content-center rounded'>
                    <RotatingLines visible={true} ariaLabel="loading" strokeColor="grey"
                                    strokeWidth="5"
                                    animationDuration="0.75" width="64" wrapperClass="progress m-auto" className='progress m-auto' />
            </div>)}
            <FormControl onSubmit={handleSubmit}>
                
                <h3><b>{t('signup.title')}</b></h3>
                <div className="form-div">
                    <label className='me-auto'>{t('signup.label_email')}</label>
                    <TextField
                        fullWidth
                        type="email"
                        className="form-field"
                        placeholder = {t('signup.placeholder_email')}
                        name='email'
                        required
                        
                        value={formData.email}
                        onChange={handleChange}
                        helperText={emailError}
                        error={emailError}
                        autoFocus="autofocus"
                        variant="outlined"
                        
                        size={'small'}
                    />
                </div>
                <br/>

                <div className="form-div">
                    <label>{t('signup.label_password')}</label>
                    <TextField
                        fullWidth
                        type={showPassword ? 'text' : 'password'}
                        className="form-field"
                        placeholder = {t('signup.placeholder_password')} 
                        name='password'
                        required
                        
                        value={formData.password}
                        onChange={handleChange}
                        helperText={passwordError}
                        error={passwordError}
                        variant="outlined"
                        
                        size={'small'}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <Icon onClick={togglePasswordVisibility}>
                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon/>}
                                </Icon>
                            </InputAdornment>
                            ),
                        }}
                    />
                    {/* <InputAdornment>
                        <VisibilityIcon />
                    </InputAdornment> */}
                </div>
                <br/>

                <div className="form-div">
                    <label>{t('signup.label_confirmPassword')}</label>
                    <TextField
                        fullWidth
                        type={showPassword ? 'text' : 'password'}
                        className="form-field"
                        placeholder = {t('signup.placeholder_confirmPassword')}
                        name='confirmPassword'
                        required

                        helperText={confirmPasswordError}
                        error={confirmPasswordError}
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        size={'small'}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <Icon onClick={togglePasswordVisibility}>
                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon/>}
                                </Icon>
                            </InputAdornment>
                            ),
                        }}
                    />
                    </div>
                <br/>

                <div className='form-div d-flex flex-row p-0'>
                    <Checkbox
                        className='ms-0 ps-0'
                        name='confirmTos'
                        checked={formData.confirmTos}
                        onChange={handleChange}
                        // size='small'
                    />
                    {<p className={'my-auto'}>
                        {t('signup.tosprivacy')}
                        <a href='/tos'>{t('signup.tos')}</a>{t('signup.and')}<a href='/privacy'>{t('signup.privacy')}</a>
                    </p>}
                    
                </div>
                <div className='d-flex'>
                    <p className='ms-0 form-error'>
                        {confirmTosError && t('signup.error_confirmTos')}
                    </p>
                    <p className='ms-0 form-error'>
                        {returnError}
                    </p>
                </div>
                <br/>

                <div className="form-div d-grid">
                    <button type="submit" className="blue_box mx-auto w-100" onClick={handleSubmit} disabled={submitDisable}>
                        {t('signup.btn_create')}
                    </button>
                    <p className="m-auto">
                        {t('signup.label_registed')} <a href="/login">{t('signup.sign_in')}</a>
                    </p>
                </div>
                
                
            </FormControl>
        </div>
    )
}