import React from 'react';
import { Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function GMTextField(props){
    //i18n
    const { t, i18n } = useTranslation(props.translation);

    if(props.translation){
        return(
            <div className="form-div d-flex flex-column">
                    <label className='ms-0 gmfield'>{t(`label_${props.field_name}`)}</label>
                    <Select
                        fullWidth
                        className="gmfield"
                        placeholder = {t(`placeholder_${props.field_name}`)} 
                        name={props.field_name}
                        variant="outlined"
                        size={'small'}
                        
                        {...props}
                    >
                        {props.children.map(function(child){
                            return(
                                <MenuItem value={child.value}>{ t(`items_${props.field_name}.${child.text}`, child.text) }</MenuItem>
                            )
                        })}
                        
                    </Select>
            </div>
        )
    }else{
        return(
            <div className="form-div d-flex flex-column">
                    <label className='ms-0 gmfield'>{props.field_name}</label>
                    <Select
                        fullWidth
                        className="gmfield"
                        placeholder = {props.field_name} 
                        name={props.field_name}
                        variant="outlined"
                        size={'small'}
                        
                        {...props}
                    >
                        {props.children.map(function(child){
                            return(
                                <MenuItem value={child.value}>{child.text}</MenuItem>
                            )
                        })}
                    </Select>
            </div>
        )
    }

    
}