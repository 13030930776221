// import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.css';

import { useEffect, useRef, Suspense, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BrowserRouter, RouterProvider } from 'react-router-dom';
import { Route, Routes, Link, Navigate } from 'react-router-dom';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import * as gmAPI from "./utils/GMillionAPI";
import {useCooker} from "./utils/cooker"
// import * as AsyncMutex from 'async-mutex';
// import axios from "axios";
import {PrivateRoute, PrivateWrapper} from './components/PrivateRoute'


import Header from './components/header/header';
import Footer from './components/footer/footer';
import * as pages from './pages';


function App() {
  const location = useLocation();
  const cooker = useCooker();
  // const refreshTokenMutex = new AsyncMutex.Mutex();
  const [isMounted, setIsMounted] = useState(true);
  gmAPI.useGMillionAPI();

  const theme = createTheme({
    palette: {
      primary: {
        main: '#07B0EE',  //blue
      },
      secondary: {
        main: '#747474',  //light gray
      },
      blue_box: {
        backgroud: '#07B0EE',
        
  //       background: #07B0EE;
  // border: 3px solid #07B0EE;
  // color: #FFFFFF;
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        xxl: 1400,
        xxxl: 1920,
      },
    },
  });

  //  Use this to do auto refreshtoken before accesstoken actual expire, however not a must, axios intercept can handle it when expire anyway just a litter slower
  // useEffect(() => {
  //   //skip this process on unload //prevent a call for refreshtoken on refreshing page and was called on onload
  //   if (window.beforeunloadTriggered || !isMounted) {
  //     return;
  //   }

  //   // alert(window.Event);

  //   // Check if accessToken is expired or about to expire
  //   if(!cooker.accessToken  //access Token is gone...
  //     || (cooker.accessToken && !cooker.preAccessTokenExpire)  //access Token is existing but preAccess is expired
  //   ){
  //     gmAPI.asyncMutexRefreshToken(); // Refresh if expired or about to expire within 1 minute
  //   }

  //   return() => {
  //     // alert('useEffect onreturn');
  //   }
  // }, [cooker.accessToken, cooker.preAccessTokenExp]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      setIsMounted(false);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isMounted]);

  return (
    <div className="App">
      
      <ThemeProvider theme={theme}>
        {/* TODO fallback to loading page */}
        <Suspense fallback="..loading">
          <Routes>
            <Route path='*' element={<Header />} />
          </Routes>

          <Routes>
            <Route exact path='/home' element={<pages.Home />} />
            <Route exact path='/coming_soon' element={<pages.Coming_soon />} />
            <Route exact path='/register' element={<pages.Register />} />
            <Route exact path='/login' element={<pages.Login />} />
            <Route exact path='/reset_password' element={<pages.Reset_password />} />

            <Route element={<PrivateWrapper />}>
              <Route path='/change_password' element={<pages.Change_password />} />

              <Route path='/setting/profile' element={<pages.Setting_profile />} />
            </Route>

            <Route path='/' element={ <Navigate to="/home" /> }/>
            <Route path='/*' element={ <Navigate to="/home" /> }/>
          </Routes>

          <Routes>
            <Route path="*" element={<Footer />} />
          </Routes>
        </Suspense>
      </ThemeProvider>
      
    </div>
  );
}

export default App;

