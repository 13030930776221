import React, {useEffect } from 'react';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Signup from './signup.js';
import EmailVer from './email_verification.js'
import EmailConfirm from './email_confirmed.js'

import "./register.css"

export default function Register(){
    //i18n
    const { t, i18n } = useTranslation('register');

    const location = useLocation();

    const email = new URLSearchParams(location.search).get("email")
    const uuid = new URLSearchParams(location.search).get("uuid")

    return(
        <div className='register'>
            <div className='context mx-auto py-5' >
                <div className='bg d-none d-sm-block ani_fade_in' style={{ 
                    backgroundImage: `url(${process.env.PUBLIC_URL + "/img/commons/bg.jpg"})`}}>
                </div>
                <div className='inner_box mx-auto p-5'>
                    {(!email && !uuid) && <Signup />}
                    {(email && !uuid) && <EmailVer /> }
                    {uuid && <EmailConfirm /> }
                </div>
            </div>
        </div>
    )
}