import React, {useEffect } from 'react';
import { useState, useRef } from "react"
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Icon from '@mui/material/Icon';
import {RotatingLines} from 'react-loader-spinner';
import * as gmAPI from "../../utils/GMillionAPI";
import { useNavigate } from "react-router-dom";
import {useCooker} from '../../utils/cooker';
import * as Validators from '../../utils/validators'
import GMFormPage from "../../components/form_page/GMformPage"

export default function Send_mail(){
    //i18n
    const { t, i18n } = useTranslation('reset_password');

    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        email: (process.env.REACT_APP_ENV === 'dev' ? 'tanit+0017@gmillion.io':""),
    });
    const [emailError, setEmailError] = useState(null);
    const [returnError, setReturnError] = useState(null);
    const [returnSuccess, setReturnSuccess] = useState(null);
    
    const validation = (e) => {
        setEmailError(null);

        //validate email
        if(!Validators.validateEmail(formData.email)){
            setEmailError(t('send_mail.error_email'));
            return false;
        }

        return true;
    }

    const handleChange = (e) => {
        const { name, value, type, files, checked } = e.target;
        const newValue = type === 'file' ? files[0] : (type === 'checkbox' ? checked : value);

        switch(name){
            case 'email':
                if(newValue.length > 128) return;
        }

        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: newValue,
        }));
    };

    const doSendMail = async () => {
        setLoading(true);
        setReturnError(null);
        setReturnSuccess(null);
        try{
            const response = await gmAPI.post(
                '/reset_pass', 
                JSON.stringify({ 
                    email: formData.email,
                })
            )

            //on success, to home
            // navigate(`/`);
            setReturnSuccess(t('send_mail.success_send_email'));
        }catch(err){
            console.log(err);

            if (!err?.response) {
                setReturnError(t('send_mail.err504'));
                // alert("No Server Response");
            }else if (err.response?.status == null){
                setReturnError(t('send_mail.err500'));
            }else{
                setReturnError( t(`send_mail.err${err.response?.status}`) );
            }
        }finally{
            setLoading(false);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if(!validation()){
            return;
        }

        await doSendMail();
    }

    
    return(
        <GMFormPage wrapperClass='send_mail' isloading={loading}>
            <FormControl onSubmit={handleSubmit}>
                <h3><b>{t('send_mail.title')}</b></h3>
                <div className='h5'>{t('send_mail.infomation')}</div>
                <div className="form-div">
                    <label className='me-auto'>{t('send_mail.label_email')}</label>
                    <TextField
                        fullWidth
                        type="email"
                        className="form-field"
                        placeholder = {t('send_mail.placeholder_email')}
                        name='email'
                        required
                        
                        value={formData.email}
                        onChange={handleChange}
                        helperText={emailError}
                        error={emailError}
                        autoFocus="autofocus"
                        variant="outlined"
                        
                        size={'small'}
                    />
                </div>
                <br/>
                <div className='d-flex'>
                    
                    <p className='ms-0 form-error'>
                        {returnError}
                    </p>
                    <p className='ms-0 form-success'>
                        {returnSuccess}
                    </p>
                </div>
                
                <br/>

                <div className="form-div d-grid">
                    <button type="conFirm" className="blue_box mx-auto w-100" 
                        onClick={handleSubmit}>
                        {t('send_mail.btn_confirm')}
                    </button>
                </div>
            </FormControl>
        </GMFormPage>
    )        
}