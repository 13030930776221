import { Button } from '@mui/material';
import React, {useEffect } from 'react';
import { useState, useRef } from "react"

import { useTranslation } from 'react-i18next';
import * as gmAPI from "../../utils/GMillionAPI";
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import {RotatingLines} from 'react-loader-spinner';

export default function Email_confirmed(){
    //i18n
    const { t, i18n } = useTranslation('register');
    const [returnError, setReturnError] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const initialized = useRef(false);
    const [countdown, setCountdown] = useState(3);

    const navigate = useNavigate();
    const location = useLocation();
    const uuid = new URLSearchParams(location.search).get("uuid")
    const code = new URLSearchParams(location.search).get("code")

    const countdownRedirect = () => {
        // const countdown = 1000;
        // setTimeout(() => {
        //     navigate('/login');
        // }, 3000);
        let count = countdown;
        const doCountdown = setInterval(() =>{
            if(count <= 0){
                clearInterval(doCountdown);
                navigate('/login');
            }else{
                setCountdown(count);
                count--;
            }
        }, 1000);
    }

    const sendVerification = async(e) => {
        setLoading(true);
        try{
            const resepone = await gmAPI.get(
                `/confirm_email?uuid=${uuid}&code=${code}`,
                null,
            )

            setIsSuccess(true);

            countdownRedirect();
            
        }catch(err){
            // alert(err);
            if (!err?.response) {
                setReturnError(t('email_confirmed.err504'));
                // alert("No Server Response");
            }else if (err.response?.status == null){
                setReturnError(t('email_confirmed.err500'));
            }else{
                setReturnError( t(`email_confirmed.err${err.response?.status}`) );
            }

        }finally{
            setLoading(false);
        }
    }


    //Run once to send email on page load
    useEffect(() => {
        if (initialized.current) {
            return;
        }

        initialized.current = true
        sendVerification(); // Run the function once on component load
    }, []);

    return(
        <div className='email_confirmed'>
            {(loading && <div className='loader d-flex justify-content-center rounded'>
                <RotatingLines visible={true} ariaLabel="loading" strokeColor="grey"
                                strokeWidth="5"
                                animationDuration="0.75" width="64" wrapperClass="progress m-auto" className='progress m-auto' />
            </div>)}
            <div className="form-div">
                <h3><b>{t('email_confirmed.title')}</b></h3>
                <p>
                    {isSuccess && t('email_confirmed.description')}
                </p>
                <p className='ms-0 form-error'>
                    {returnError}
                </p>
                <br/>
                
            </div>
        </div>
    )
}