import React, {useEffect } from 'react';
import { useRef, useState } from "react"
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import * as gmAPI from "../../utils/GMillionAPI";

import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import GMTextField from '../../components/GMTextField'
// import { Select, MenuItem } from '@mui/material';
import GMSelectField from '../../components/GMSelectField'
import GMFormPage from "../../components/form_page/GMformPage"
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Icon from '@mui/material/Icon';

import {RotatingLines} from 'react-loader-spinner';
import {useCooker} from "../../utils/cooker"
// import { countries } from 'i18n-iso-countries'
import iso3166 from 'iso-3166-1';
import { Button } from 'react-bootstrap';



export default function SettingProfile(){
    //i18n
    const { t, i18n } = useTranslation('setting/profile');

    const cooker = useCooker();
    const inputRef = useRef(null);
    const initialized = useRef(false);
    const [enableEditing, setEnableEditing] = useState(false);
    const [returnError, setReturnError] = useState(null);

    
    const [formError, setFormError] = useState({
        displayname: null,
    })
    const [loading, setLoading] = useState(false);
    
    const handleChange = (e) => {
        const { name, value, type, files, checked } = e.target;
        const newValue = type === 'file' ? files[0] : (type === 'checkbox' ? checked : value);

        switch(name){
            case 'email':
                if(newValue.length > 128) return;

            // case 'password':
            // case 'confirmPassword':
            //     if(newValue.length > 32) return;
        }

        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: {
            ...prevFormData[name],
            value: newValue,
          }
        }));
    };

    const defInitField = {
        value: null,
        onChange: handleChange,
        helperText: null,
        error: null,
        translation: 'setting/profile',
    }

    const [formData, setFormData] = useState({
        email: {
            field_name: 'email',
            ...defInitField,
        },
        // displayname: null,   //todo...
        firstname: {
            field_name: "firstname",
            ...defInitField,
        },
        lastname: {
            field_name: "lastname",
            ...defInitField,
        },
        gender: {
            field_name: "gender",
            ...defInitField,
            children: [
                {value: 'm', text: 'male'},
                {value: 'f', text: 'female'},
                {value: 'u', text: 'undefined'},
            ],
        },
        birthdate: {
            field_name: "birthdate",
            ...defInitField,
            type: 'date',
            // value: (new Date().toISOString().slice(0, 10)),
        },
        address1: {
            field_name: "address1",
            ...defInitField,
        },
        address2: {
            field_name: "address2",
            ...defInitField,
        },
        zip: {
            field_name: "zip",
            ...defInitField,
        },
        city: {
            field_name: "city",
            ...defInitField,
        },
        country: {
            field_name: "country",
            ...defInitField,
            children: [],
        },
        // tel: null,  //todo OTP
    });

    const validation = (e) => {
        var isvalid = true;
        setFormData((prevFormData) => ({
            ...prevFormData,
            firstname: {
                ...prevFormData.firstname,
                ...(    (formData.firstname.value?.length > 0)?
                        {helperText: null, error: null}
                        :(() => { 
                            isvalid = false;
                            return {helperText: t('error_firstname'), error: true}
                        })()
                    ),
                
            },
            lastname: {
                ...prevFormData.lastname,
                ...((formData.lastname.value?.length > 0)?
                        {helperText: null, error: null}
                        :{helperText: t('error_lastname'), error: true}),
                
            },
            gender: {
                ...prevFormData.gender,
                ...((formData.gender.value?.length > 0)?
                        {helperText: null, error: null}
                        :{helperText: t('error_gender'), error: true}),
                
            },
            birthdate: {
                ...prevFormData.birthdate,
                ...((formData.birthdate.value?.length > 0)?
                        {helperText: null, error: null}
                        :{helperText: t('error_birthdate'), error: true}),
            }
        }));

        if(!isvalid)alert('test');

        if((formData.firstname.value?.length > 0)
            && (formData.lastname.value?.length > 0)
            && (formData.gender.value?.length > 0)
            && (formData.birthdate.value?.length > 0)
        ){
                return true;
        }

        return false;
    }

    const updateUserProfile = async (e) => {
        setLoading(true);
        setReturnError(null);

        try{
            const response = await gmAPI.post(
                '/setting/profile',
                {
                    firstname: formData.firstname.value,
                    lastname: formData.lastname.value,
                    gender: formData.gender.value,
                    birthdate: formData.birthdate.value,
                    address1: formData.address1.value ?? '',
                    address2: formData.address2.value ?? '',
                    zip: formData.zip.value ?? '',
                    city: formData.city.value ?? '',
                    country: formData.country.value ?? '',
                }
            )
        }catch(err){
            console.log(err);
            if (!err?.response) {
                setReturnError(t('err504'));
                // alert("No Server Response");
            }else if (err.response?.status == null){
                setReturnError(t('err500'));
            }else{
                setReturnError( t(`err${err.response?.status}`) );
            }
            
        }finally{
        }
    }

    const handleEdit = async (e) => {
        setEnableEditing(true);
    }

    const handleCancel = async (e) => {
        readUserProfile();
        setEnableEditing(false);
    }

    const handleSubmit = async (e) => {
        setLoading(true);
        if(validation()){
            updateUserProfile();
            setEnableEditing(false);
        }
        setLoading(false);
    }


    const readCountries = async() => {
        // Fetching the list of countries from an API
        fetch('https://restcountries.com/v3.1/all')
            .then(response => response.json())
            .then(data => {
                // Extracting country names and cca2 codes from the data
                const countryInfo = data.map(country => ({
                text: country.name.common,
                value: country.cca2
                }));
                const sorted = [...countryInfo].sort((a,b)=>a.text.localeCompare(b.text));
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    country: {
                        ...prevFormData['country'],
                        children: sorted,
                    }
                }));
            })
          .catch(error => console.log(error));
    }

    const readUserProfile = async() => {
        setLoading(true);
        setReturnError(null);

        try{
            const response = await gmAPI.get(
                '/setting/profile',
            )

            const profile = response.data?.data?.profile;

            setFormData((prevFormData) => ({
                ...prevFormData,
                email: {
                    ...prevFormData.email,
                    value: profile.email,
                },
                firstname: {
                    ...prevFormData.firstname,
                    value: profile.firstname,
                },
                lastname: {
                    ...prevFormData.lastname,
                    value: profile.lastname,
                },
                gender: {
                    ...prevFormData.gender,
                    value: profile.gender,
                },
                birthdate: {
                    ...prevFormData.birthdate,
                    value: new Date(profile.birthdate).toISOString().substring(0, 10),
                },
                address1: {
                    ...prevFormData.address1,
                    value: profile.address1,
                },
                address2: {
                    ...prevFormData.address2,
                    value: profile.address2,
                },
                zip: {
                    ...prevFormData.zip,
                    value: profile.zip,
                },
                city: {
                    ...prevFormData.city,
                    value: profile.city,
                },
                country: {
                    ...prevFormData.country,
                    value: profile.country,
                },
                
              }));

        }catch(err){
            console.log(err);
            if (!err?.response) {
                setReturnError(t('err504'));
                // alert("No Server Response");
            }else if (err.response?.status == null){
                setReturnError(t('err500'));
            }else{
                setReturnError( t(`err${err.response?.status}`) );
            }
        }finally{
            setLoading(false);
        }
    }

    //Run once to send email on page load
    useEffect(() => {
        if (initialized.current) {
            return;
        }

        initialized.current = true

        readCountries();
        
        //read user's profile
        readUserProfile();
    }, []);


    return(
        <div className='setting-profile'>
            <GMFormPage wrapperClass='login' isloading={loading}>
                <FormControl onSubmit={handleSubmit} className='w-100 maxw-768 px-2'>
                    <h3><b>{t('title')}</b></h3>
                    <Grid container spacing={2} >
                        <Grid item xs={12}>
                            <GMTextField {...formData.email} required disabled  
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GMTextField {...formData.firstname} required inputProps={{ maxLength: 50 }} disabled={!enableEditing}/>
                        </Grid>

                        <Grid item xs={12}>
                            <GMTextField {...formData.lastname} required inputProps={{ maxLength: 50 }} disabled={!enableEditing}/>
                        </Grid>

                        <Grid item xs={6}>
                            <GMSelectField {...formData.gender} disabled={!enableEditing} />
                        </Grid>

                        <Grid item xs={6}>
                            <GMTextField {...formData.birthdate} disabled={!enableEditing} />
                        </Grid>
                        <Grid item xs={12}><GMTextField {...formData.address1} inputProps={{ maxLength: 100 }} disabled={!enableEditing}/></Grid>
                        <Grid item xs={12}><GMTextField {...formData.address2} inputProps={{ maxLength: 100 }} disabled={!enableEditing}/></Grid>

                        <Grid item xs={6}>
                            <GMTextField {...formData.zip} inputProps={{ maxLength: 50 }} disabled={!enableEditing}/>
                        </Grid>
                        <Grid item xs={8}><GMTextField {...formData.city} inputProps={{ maxLength: 50 }} disabled={!enableEditing}/></Grid>

                        <Grid item xs={10}>
                            <GMSelectField {...formData.country} disabled={!enableEditing} />
                        </Grid>
                        <Grid item xs={6} md={8}></Grid>
                    </Grid>
                    <br/>
                    <div className='w-100 d-flex justify-content-end'>
                        {enableEditing && <Button className='me-2' onClick={handleCancel} >{t('button_cancel')}</Button>}
                        {enableEditing && <Button type="submit" onClick={handleSubmit} >{t('button_save')}</Button>}
                        {!enableEditing && <Button onClick={handleEdit} >{t('button_edit')}</Button>}

                    </div>
                    
                </FormControl>
            </GMFormPage>
        </div>
    )
}