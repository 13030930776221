import React from 'react';
import { useState, useEffect, useRef } from "react"
import { Route, Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
// import { useCookies } from 'react-cookie';
import {useCooker} from '../utils/cooker';
import * as gmAPI from "../utils/GMillionAPI";

// const PrivateRoute = ({ element: Element, ...rest }) => {
//   // const [cookies, setCookie, removeCookie] = useCookies(['accessToken', 'refreshToken']);
//   const cooker = useCooker();
//   const [hasAccess, setHasAccess] = useState(true);
//   const [isMounted, setIsMounted] = useState(true);

//   useEffect(() => {
//     //skip this process on unload //prevent a call for refreshtoken on refreshing page and was called on onload
//     if (window.beforeunloadTriggered || !isMounted) {
//       return;
//     }

//     if(!cooker.accessToken()){
//       if(!cooker.refreshToken()){
//         //No RefreshToken
//         setHasAccess(false);
//         return;
//       }

//       //No AccessToken but RefreshToken still existing
//       gmAPI.asyncMutexRefreshToken();
//     }
//   }, [cooker.accessToken]);

//   useEffect(() => {
//     const handleBeforeUnload = (event) => {
//       setIsMounted(false);
//     };

//     window.addEventListener('beforeunload', handleBeforeUnload);

//     // Clean up the event listener
//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, [isMounted]);

//   return (
//     <Route
//       {...rest}
//       render={(props) =>
//         // accessToken ? <Component {...props} /> : <Redirect to={`/login?redirect=${props.location.pathname}`} />
//         hasAccess ?
//           <Element {...props} />
//           :  <Navigate to={`/login?redirect=${props.location.pathname}`} />
//       }
//     />
//   );
// };
  
// export default PrivateRoute;

export const PrivateWrapper = () => {
  const location = useLocation();
  const cooker = useCooker();
  const [hasAccess, setHasAccess] = useState(true);
  const [isMounted, setIsMounted] = useState(true);
  const navigate = useNavigate();

  const authAccessToken = async() => {
    try{
      const response = await gmAPI.get(
        '/auth', 
        '{}',
      )
    }catch(err){
      // console.log(err);
      //any error on auth check just remove the Token, then redirect to login
      cooker.removeRefreshToken();
      cooker.removeAccessToken();
      navigate(`/login?redirect=${location.pathname.substring(1)}`);
    }
  }

  useEffect(() => {
    //skip this process on unload //prevent a call for refreshtoken on refreshing page and was called on onload
    if (window.beforeunloadTriggered || !isMounted) {
      return;
    }

    if(!cooker.accessToken()){
      if(!cooker.refreshToken()){
        setHasAccess(false);
      }
      // console.log("Private Route", "refreshtoken...");
      gmAPI.asyncMutexRefreshToken();
    }else{
      //On Access token exist > do Authen check once
      authAccessToken();
    }
  
  });

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      setIsMounted(false);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isMounted]);

  return hasAccess ? <Outlet /> :  <Navigate to={`/login?redirect=${location.pathname.substring(1)}`} />
}