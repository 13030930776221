import { Button } from '@mui/material';
import React, {useEffect } from 'react';
import { useState, useRef } from "react"
import { useLocation } from 'react-router-dom';
import * as gmAPI from "../../utils/GMillionAPI";

import { useTranslation } from 'react-i18next';
import { use } from 'i18next';
import {RotatingLines} from 'react-loader-spinner';

export default function Email_verification(){
    //i18n
    const { t, i18n } = useTranslation('register');

    const [returnError, setReturnError] = useState(null);
    const [sendDisable, setSendDisable] = useState(false);
    const [loading, setLoading] = useState(false);
    const initialized = useRef(false);

    const location = useLocation();
    const email = new URLSearchParams(location.search).get("email")

    const sendVerEmail = async(e) => {
        setReturnError(null);
        setSendDisable(true);
        setLoading(true);

        try{
            const resepone = await gmAPI.post(
                '/confirm_email',
                JSON.stringify({ 
                    email: email,
                }),
            )

            //TODO success email send > make delay countdown
            setSendDisable(false);
        }catch(err){
            if (!err?.response) {
                setReturnError(t('email_ver.err504'));
                // alert("No Server Response");
            }else if (err.response?.status == null){
                setReturnError(t('email_ver.err500'));
            }else{
                setReturnError( t(`email_ver.err${err.response?.status}`) );
            }
            
            setSendDisable(false);
            
        }finally{
            setLoading(false);
        }
    }

    //Run once to send email on page load
    useEffect(() => {
        if (initialized.current) {
            return;
        }

        initialized.current = true
        
        sendVerEmail(); // Run the function once on component load
    }, []);
    
    return(
        <div className='email_verification'>
            {(loading && <div className='loader d-flex justify-content-center rounded'>
                <RotatingLines visible={true} ariaLabel="loading" strokeColor="grey"
                                strokeWidth="5"
                                animationDuration="0.75" width="64" wrapperClass="progress m-auto" className='progress m-auto' />
            </div>)}
            <div className="form-div">
                <h3><b>{t('email_ver.title')}</b></h3>
                <p>
                    {!returnError && t('email_ver.description')}
                </p>
                <br/>
                <p className='ms-0 form-error'>
                    {returnError}
                </p>
                <button className="blue_box mx-auto w-100" onClick={sendVerEmail} disabled={sendDisable}>
                    {t('email_ver.btn_resend')}
                </button>
            </div>
        </div>
    )
}