import React, {useEffect } from 'react';
import { useState, useRef } from "react"
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Icon from '@mui/material/Icon';
import {RotatingLines} from 'react-loader-spinner';
import * as gmAPI from "../../utils/GMillionAPI";
import { useNavigate } from "react-router-dom";
import {useCooker} from '../../utils/cooker';
import * as Validators from '../../utils/validators'
import GMFormPage from "../../components/form_page/GMformPage"

export default function Change_password(){
    //i18n
    const { t, i18n } = useTranslation('reset_password');

    const navigate = useNavigate();
    const location = useLocation();

    const [formData, setFormData] = useState({
        newPassword: (process.env.REACT_APP_ENV === 'dev' ?'12345678@aAb':''),
        confirmPassword: (process.env.REACT_APP_ENV === 'dev' ?'12345678@aAb':''),
    });

    const [newPasswordError, setNewPasswordError] = useState(null);
    const [confirmPasswordError, setConfirmPasswordError] = useState(null);
    const [returnError, setReturnError] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isMounted, setIsMounted] = useState(true);
    const [tokenVerified, setTokenVerified] = useState(false);
    const [showInputForm, setShowInputForm] = useState(false);

    const token = new URLSearchParams(location.search).get("token")

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleChange = (e) => {
        const { name, value, type, files, checked } = e.target;
        const newValue = type === 'file' ? files[0] : (type === 'checkbox' ? checked : value);

        switch(name){
            case 'newPassword':
            case 'confirmPassword':
                if(newValue.length > 32) return;
        }

        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: newValue,
        }));
    };

    const validation = (e) => {
        setNewPasswordError(null);
        setConfirmPasswordError(null);

        //validate password
        if(!Validators.validatePassword(formData.newPassword)){
            setNewPasswordError(t('change_password.error_newPassword'))
            return false;
        }

        //validate confirm password
        if(!(formData.newPassword == formData.confirmPassword)){
            setConfirmPasswordError(t('change_password.error_confirmPassword'));
            return false;
        }

        return true;
    }

    const doChangePassword = async () => {
        setReturnError(null);
        try{
            const response = await gmAPI.put(
                '/reset_pass', 
                JSON.stringify({ 
                    token: token,
                    newpass: formData.newPassword,
                })
            )

            //on success, to home
            navigate(`/login`);
        }catch(err){
            console.log(err);

            if (!err?.response) {
                setReturnError(t('err504'));
                // alert("No Server Response");
            }else if (err.response?.status == null){
                setReturnError(t('err500'));
            }else{
                setReturnError( t(`err${err.response?.status}`) );
            }
        }finally{
            setLoading(false);
        }

    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if(!validation()){
            setLoading(false)
            return;
        }

        doChangePassword();
    }

    const verifyToken = async() => {
        try{
            const response = await gmAPI.get(
                `/reset_pass?token=${token}`, 
                null
            )
            setShowInputForm(true);

            //on success, to home
            // navigate(`/`);
        }catch(err){
            console.log(err);
            setShowInputForm(false);

            if (!err?.response) {
                setReturnError(t('change_password.err504'));
                // alert("No Server Response");
            }else if (err.response?.status == null){
                setReturnError(t('change_password.err500'));
            }else{
                setReturnError( t(`change_password.err${err.response?.status}`) );
            }
        }finally{
            setLoading(false);
        }
    }

    //verify token once 
    useEffect(() => {
        //skip this process on unload //prevent a call for refreshtoken on refreshing page and was called on onload
        if (window.beforeunloadTriggered || !isMounted) {
          return;
        }

        if(tokenVerified) { return; }

        setTokenVerified(true);
        verifyToken();
    
        // if(!cooker.accessToken()){
        //   if(!cooker.refreshToken()){
        //     setHasAccess(false);
        //   }
        //   // console.log("Private Route", "refreshtoken...");
        //   gmAPI.asyncMutexRefreshToken();
        // }
      
    });

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          setIsMounted(false);
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        // Clean up the event listener
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isMounted]);

    return(
        <GMFormPage wrapperClass='change_password' isloading={loading}>
             <FormControl onSubmit={handleSubmit}>
                <h3><b>{t('change_password.title')}</b></h3>
                {showInputForm && <div>
                    <div className="form-div">
                        <label>{t('change_password.label_newPassword')}</label>
                        <TextField
                            fullWidth
                            type={showPassword ? 'text' : 'password'}
                            className="form-field"
                            placeholder = {t('change_password.placeholder_newPassword')} 
                            name='newPassword'
                            required
                            
                            value={formData.newPassword}
                            onChange={handleChange}
                            helperText={newPasswordError}
                            error={newPasswordError}
                            variant="outlined"
                            
                            size={'small'}
                            InputProps={{
                                endAdornment: (
                                <InputAdornment position="end">
                                    <Icon onClick={togglePasswordVisibility}>
                                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon/>}
                                    </Icon>
                                </InputAdornment>
                                ),
                            }}
                        />
                        {/* <InputAdornment>
                            <VisibilityIcon />
                        </InputAdornment> */}
                    </div>
                    <br/>

                    <div className="form-div">
                        <label>{t('change_password.label_confirmPassword')}</label>
                        <TextField
                            fullWidth
                            type={showPassword ? 'text' : 'password'}
                            className="form-field"
                            placeholder = {t('change_password.placeholder_confirmPassword')}
                            name='confirmPassword'
                            required

                            helperText={confirmPasswordError}
                            error={confirmPasswordError}
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            size={'small'}
                            InputProps={{
                                endAdornment: (
                                <InputAdornment position="end">
                                    <Icon onClick={togglePasswordVisibility}>
                                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon/>}
                                    </Icon>
                                </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <br/>
                </div>}

                <div className='d-flex'>
                    <p className='ms-0 form-error'>
                        {returnError}
                    </p>
                </div>
                <br/>

                {showInputForm && <div className="form-div d-grid">
                    <button type="conFirm" className="blue_box mx-auto w-100" 
                        onClick={handleSubmit}
                    >
                        {t('change_password.btn_confirm')}
                    </button>
                </div>}
            </FormControl>
        </GMFormPage>
    )

}