import React, { useState, useEffect } from 'react';
import { Container, Nav, Navbar, Row, Col } from 'react-bootstrap';
import Image from 'react-bootstrap/esm/Image';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { matchRoutes, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {maskEmail} from '../../utils/maskers'
import { Dropdown } from 'react-bootstrap';

import {useCooker} from "../../utils/cooker"

import "./header.css";

export default function Header(){
    const location = useLocation();
    const navigate = useNavigate();

    //i18n
    const { t, i18n } = useTranslation('header');
    // const [isth, setlang] = useState(true)

    const cooker = useCooker();
    const [email, setEmail] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [isToggle, setIsToggle] = useState('')

    const changeLanguage = bt => {
        // setlang(!isth);
        if(i18n.language == 'th'){
            i18n.changeLanguage('en');
        }else{
            i18n.changeLanguage('th');
            
        }
    }

    const doToggle = () => {
        setToggle(!toggle);
        if(!toggle){
            setIsToggle('isToggle')
        }else{
            setIsToggle('');
        }

    }

    //navbar scroll when active state
    const [navbarAway, setNavbar] = useState('')

    //navbar scroll changing function
    const checkNavbarAway = () => {
        // console.log(window.scrollY)
        if (window.scrollY >= 128) {
            setNavbar('navaway')
        } else {
            setNavbar('')
        }
    }

    const handleLogout = () => {
        cooker.removeAccessToken();
        cooker.removeRefreshToken();
        cooker.removeEmail();
        navigate('/home');    //force re-rendering since all useEffect take place before value change
    }

    useEffect(() => {
        checkNavbarAway()
        // adding the event when scroll change background
        window.addEventListener("scroll", checkNavbarAway)
    })

    useEffect(() => {
        setIsLoggedIn(cooker.email() != null);
        setEmail(cooker.email());
    })

    const tag_menu = () => {
        switch(location.pathname){
            case '/':
            case '/home':
                return(
                    <div className='d-flex'>
                        <Nav.Link href="#home_p2"><span className={'p-2 me-3 h6 bold gray80 ani_fade_in '}>{t('WHO_WE_ARE')}</span></Nav.Link>
                        <Nav.Link href="#home_projects"><span className={'p-2 me-3 h6 bold gray80 ani_fade_in ' }>{t('OUR_PRODUCT')}</span></Nav.Link>
                        <Nav.Link href="#home_p4"><span className={'p-2 me-3 h6 bold gray80 ani_fade_in ' }>{t('MILESTONE')}</span></Nav.Link>
                        <Nav.Link href="#home_end"><span className={'p-2 h6 bold gray80 ani_fade_in ' }>{t('CONTACT')}</span></Nav.Link>
                    </div>
                )

        }
        return (<div></div>);
    }

    const LoginPanel = (justify) => {
        return(
            <div className='login_panel'>
                {console.log("isLoggedIn: " + isLoggedIn)}
                {console.log("email: " + cooker.email())}
                {isLoggedIn && <div className='me-0 d-flex justify-content-end'>
                    <Dropdown>
                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                            <span className='h6 gray80'>{maskEmail(email)}</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {/* <Dropdown.Item href="#action1">Action 1</Dropdown.Item> */}
                            <Dropdown.Item tabIndex={0} href="/change_password">{t('menu_change_password')}</Dropdown.Item>
                            <Dropdown.Item className='gray' tabIndex={1} href="/setting/profile">{t('menu_profile')}</Dropdown.Item>
                            <Dropdown.Item tabIndex={9} onClick={handleLogout}>{t('menu_logout')}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                </div>}
                {!isLoggedIn && <div className='mx-auto d-flex justify-content-end '>
                    <Link to="/register">
                        <button className={'tran_sbox me-2 ' + i18n.language} id='p1_b1'>{t('REGISTER')}</button>
                    </Link>
                    <Link to="/login">
                        <button className={'blue_sbox ' + i18n.language} id='p1_b1'>{t('LOGIN')}</button>
                    </Link>
                </div>}
            </div>
        )
    }
    

    return(
        <Navbar className={'header mx-auto my-0 px-0 py-0 lang-'+ i18n.language + ' ' + isToggle + ' ' + navbarAway} collapseOnSelect expand="xl" sticky='top' variant="light">
            <div className='d-none d-lg-block w-100 my-auto p-2'>
                <div className='header-lg d-flex flex-row justify-content-between align-items-center'>
                    <Nav id="nav-brand" className="ms-5 ">
                        <Link to="/home">
                            <Image id="img-logo" className={"logo " + navbarAway} src='/img/header/gmillion_logo.png' alt="G Million" />
                        </Link>
                    </Nav>
                    <div id="div-links" className='m-auto d-flex justify-content-evenly'>
                        {tag_menu()}
                    </div>
                    <Nav id="nav-lang" className="me-1 d-flex flex-row justify-content-end align-items-center">
                        <div className='mx-auto '>
                            <Image id='img_lang' fluid className='lang me-2 link-icon ani_fade_in '
                                src={i18n.language=='th' ? '/img/header/lang_th.png' : '/img/header/lang_en.png' } alt="lang" 
                                onClick={changeLanguage} />
                        </div>
                        <div className='me-5 d-flex justify-content-evenly '>
                            <LoginPanel />
                        </div>
                    </Nav>
                    
                </div>
            </div>
            <div fluid className='container-fluid mx-2 my-auto py-0 d-block d-lg-none mx-auto justify-content-between' id='header-sm'>
                <div id="div-header-sm" className='py-2 m-auto d-flex justify-content-between'>
                    <div className='d-flex'>
                        <Nav id="nav-brand" href="/home" className="p-0 m-auto d-none d-sm-block">
                            <Link to="/home">
                                <Image id="img-logo" className={"logo " + navbarAway} src='./img/header/gmillion_logo.png' alt="G Million" />
                            </Link>
                        </Nav>
                        <Navbar.Toggle id='nav_toggle' aria-controls="basic-navbar-nav" className='hamburger d-block d-lg-none' 
                            onClick={()=> doToggle()} >
                            <img fluid id='img-burger' className='m-auto p-0' 
                                src={isToggle ? './img/header/hamburger_close.png' : './img/header/hamburger.png'} 
                            />
                        </Navbar.Toggle>
                        
                    </div>
                    
                    {!isToggle && 
                        <Nav id="nav-lang" className="my-auto d-flex flex-row align-items-end">
                            <div className='mx-auto my-auto '>
                                <Image id='img_lang' fluid className='lang me-1 link-icon ani_fade_in '
                                    src={i18n.language=='th' ? '/img/header/lang_th.png' : '/img/header/lang_en.png' } alt="lang" 
                                    onClick={changeLanguage} />
                            </div>
                            <div className='mx-auto d-flex justify-content-end d-block'>
                                <LoginPanel />
                            </div>
                            {/* TODO <sm size menu page button */}
                        </Nav>
                    }
                    
                </div>
            </div>
            <Navbar.Collapse id="basic-navbar-nav" className='hamburger-menus m-0 p-0'>
                {/* Mobile potriat only Collapse Menu -full screen style-*/}
                <div className=' d-flex d-block d-lg-none'>
                    <div className='hamburger-menus-inner d-flex flex-column'>
                        <div className='m-auto'>
                            <img fluid src='/img/header/gmillion_logo_hamburger_menu.png' className='hamburger-logo mx-auto ani_fade_in' />
                            <p>&nbsp;</p>
                            <Nav.Link onClick={()=> doToggle()} href="#" className='mx-auto h2 white bold ani_fade_in'>{t('HOME')}</Nav.Link>
                            <Nav.Link onClick={()=> doToggle()} href="#home_p2" className='mx-auto h2 white bold ani_fade_in'>{t('WHO_WE_ARE')}</Nav.Link>
                            <Nav.Link onClick={()=> doToggle()} href="#home_projects" className='mx-auto h2 white bold ani_fade_in'>{t('OUR_PRODUCT')}</Nav.Link>
                            <Nav.Link onClick={()=> doToggle()} href="#home_p4" className='mx-auto h2 white bold ani_fade_in'>{t('MILESTONE')}</Nav.Link>
                            <Nav.Link onClick={()=> doToggle()} href="#home_end" className='mx-auto h2 white bold ani_fade_in'>{t('CONTACT')}</Nav.Link>
                            
                        </div>
                        <div className={'m-auto p-1 copyright h6 gray' } id='copy'>
                                <h6>© 2023 G MILLION Co., Ltd All Right Reserved</h6>
                        </div>
                    </div>
                    <img className='bg' src='./img/commons/home_bg.jpg' alt='Smile Power'/>
                </div>
            </Navbar.Collapse>
        </Navbar>
    )
    
}