import React, {useEffect } from 'react';
import { useState, useRef } from "react"
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Icon from '@mui/material/Icon';
import {RotatingLines} from 'react-loader-spinner';
import * as gmAPI from "../../utils/GMillionAPI";
import { useNavigate } from "react-router-dom";
import {useCooker} from '../../utils/cooker';

import GMFormPage from "../../components/form_page/GMformPage"

import "./login.css"

export default function Login(){
    //i18n
    const { t, i18n } = useTranslation('login');

    const location = useLocation();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        email: (process.env.REACT_APP_ENV === 'dev' ? 'tanit+0017@gmillion.io' : ''),
        password: (process.env.REACT_APP_ENV === 'dev' ? '12345678@aA' : ''),
    });
    const [emailError, setEmailError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);
    const [returnError, setReturnError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    // const [, setRefreshTokenCookie] = useCookies(['refreshToken']);
    const cooker = useCooker();

    const redirect = new URLSearchParams(location.search).get("redirect")

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleChange = (e) => {
        const { name, value, type, files, checked } = e.target;
        const newValue = type === 'file' ? files[0] : (type === 'checkbox' ? checked : value);

        switch(name){
            case 'email':
                if(newValue.length > 128) return;

            case 'password':
            case 'confirmPassword':
                if(newValue.length > 32) return;
        }

        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: newValue,
        }));
    };

    const doLogin = async () => {
        setLoading(true);
        try {
            const response = await gmAPI.post(
                '/auth', 
                JSON.stringify({ 
                    email: formData.email,
                    password: formData.password,
                })
            )

            //{uuid, accessToken, refreshToken, expiration_time},
            const {uuid, accessToken, refreshToken, accessToken_expiration_time, refreshToken_expiration_time} = response.data.data;

            // // Set access token as a cookie
            await cooker.setEmail(formData.email);
            await cooker.setAccessToken(accessToken, accessToken_expiration_time);
            await cooker.setRefreshToken(refreshToken, refreshToken_expiration_time);
            
            
            //redirect to
            if(redirect){
                navigate(`/${redirect}`);
            }else{
                navigate(`/`);
            }

        }catch(err){
            console.log(err);

            if (!err?.response) {
                setReturnError(t('err504'));
                // alert("No Server Response");
            }else if (err.response?.status == null){
                setReturnError(t('err500'));
            }else if (err.response?.status == '409'){
                //redirect on email not yet verified
                // console.log(encodeURIComponent(formData.email));
                navigate(`/register?email=${encodeURIComponent(formData.email)}`);
            }else{
                setReturnError( t(`err${err.response?.status}`) );
            }
        }finally{
            setLoading(false);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        await doLogin();
    }

    const onTextKeyDown = (e) => {
        if(e.key === 'Enter'){
            e.preventDefault();
            doLogin();
        }
    }

    //redirect to home if user is already login somehow
    useEffect(() => {
        if(cooker.refreshToken() != null){
            if(redirect){
                navigate(`/${redirect}`);
            }else{
                navigate(`/`);
            }
        }
    })
    

    return(
        <GMFormPage wrapperClass='login' isloading={loading}>
                    <FormControl onSubmit={handleSubmit}>
                        <h3><b>{t('title')}</b></h3>
                        <div className="form-div">
                            <label className='me-auto'>{t('label_email')}</label>
                            <TextField
                                fullWidth
                                type="email"
                                className="form-field"
                                placeholder = {t('placeholder_email')}
                                name='email'
                                required
                                
                                value={formData.email}
                                onChange={handleChange}
                                helperText={emailError}
                                error={emailError}
                                autoFocus="autofocus"
                                variant="outlined"
                                
                                size={'small'}
                            />
                        </div>
                        <br/>

                        <div className="form-div">
                            <label>{t('label_password')}</label>
                            <TextField
                                fullWidth
                                type={showPassword ? 'text' : 'password'}
                                className="form-field"
                                placeholder = {t('placeholder_password')} 
                                name='password'
                                required
                                
                                value={formData.password}
                                onChange={handleChange}
                                helperText={passwordError}
                                error={passwordError}
                                variant="outlined"
                                
                                size={'small'}
                                InputProps={{
                                    endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon onClick={togglePasswordVisibility}>
                                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon/>}
                                        </Icon>
                                    </InputAdornment>
                                    ),
                                }}
                                onKeyDown={onTextKeyDown}
                            />
                            {/* <InputAdornment>
                                <VisibilityIcon />
                            </InputAdornment> */}
                        </div>

                        <div className='d-flex'>
                            
                            <p className='ms-0 form-error'>
                                {returnError}
                            </p>
                        </div>
                        <br/>

                        <div className="form-div d-grid">
                            <button type="submit" className="blue_box mx-auto w-100" 
                                onClick={handleSubmit} >
                                {t('btn_login')}
                            </button>
                        </div>
                        <hr/>
                        <div><a href='/reset_password'>{t('link_reset_password')}</a></div>
                        <div><a href='/register'>{t('link_register')}</a></div>
                    
                    </FormControl>
        </GMFormPage>
    )
}